import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'
import { PoolCategory } from 'config/constants/types'
import tokens from 'config/constants/tokens'

// Addresses
import {
  getAddress,
  getMulticallAddress,
  getOldPYEAddress,
  getOldAppleAddress,
} from 'utils/addressHelpers'

// ABI
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import cakeAbi from 'config/abi/cake.json'
import oldPyeAbi from 'config/abi/oldpyeabi.json'
import oldAppleAbi from 'config/abi/oldappleabi.json'
import migrationAbi from 'config/abi/migrationabi.json'
import ifoV1Abi from 'config/abi/ifoV1.json'
import ifoV2Abi from 'config/abi/ifoV2.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'
import MultiCallAbi from 'config/abi/Multicall.json'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getErc721Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, signer)
}
export const getLpContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpTokenAbi, address, signer)
}
export const getCakeContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, tokens.cake.address, signer)
}
export const getOldPYEContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(oldPyeAbi, getOldPYEAddress(), signer)
}
export const getOldAppleContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(oldAppleAbi, getOldAppleAddress(), signer)
}
export const getMigrationContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(migrationAbi, address, signer)
}
export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}
