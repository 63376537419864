export default {
  multiCall: {
    4: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
    1: '0xb0A452DcB9c7cC99bA6a16A0583b8e18e9D3A4c1',
  },
  migration: {
    1: '0xC7503d00Be89d78df8f97cCcD4cf5F7DB6e1fAa9',
    4: '',
  },
  oldPYE: {
    1: '0x5B232991854c790b29d3F7a145a7EFD660c9896c',
    4: '',
  },
  oldForce: {
    1: '0x82ce8A98Bf1c5daBe24620220dd4bc5da0ba291d',
    4: '',
  },
  oldApple: {
    1: '0x6f43a672D8024ba624651a5c2e63D129783dAd1F',
    4: '',
  },
  oldCherry: {
    1: '0xD2858A1f93316242E81CF69B762361F59Fb9b18E',
    4: '',
  },
  pye: {
    1: '0x59f4cdBF88cBd8e3D34B00828d0b43d406F79B4e',
    4: '',
  },
  apple: {
    1: '0x717f8316e497456662ebAeE099Ac6bdAA1E62482',
    4: '',
  },
  cherry: {
    1: '0x621d1C61843c43cA8D84E6480338df1DB3e068A7',
    4: '',
  },
}
